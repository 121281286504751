<template>
  <div
          v-wechat-title="
      ($route.meta.title = bookInfo.name
        ? bookInfo.name + '-章節-嗨文小說'
        : '嗨文小說')
    "
          :class="{
      chapterWrap: true,
      night: bgCurrent == 5,
      green: bgCurrent == 3,
      blue: bgCurrent == 4,
      pink: bgCurrent == 1,
      yellow: bgCurrent == 2
    }"
  >
    <div :class="{ fixed: true, isShow: isShowSet }">
      <div class="topbox clearfix allN">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/book/back@2x.png" alt="" />
        </a>
        <p class="fl">{{ bookInfo.name }}</p>
        <a href="javaScript:" class="fr more" @click="goMore">
          <img src="~img/book/share@2x.png" alt="" />
        </a>
      </div>
      <div class="options" v-if="moreShow">
        <img class="bg" src="~img/book/bg_list@2x.png" alt="" />
        <ul class="moreOption">
          <li>
            <router-link :to="'/book/' + bookInfo.id" class="clearfix">
              <div class="fl detailimg">
                <img :src="bookInfo.iconUrl" alt="" />
              </div>
              <div class="bookright fl">
                <p class="name ellipsis">{{ bookInfo.name }}</p>
                <p class="author ellipsis">{{ authorinfo.name }}</p>
                <p class="more">查看書籍詳情 ></p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link class="clearfix" to="/collect">
              <img class="fl" src="~img/book/icon_section@2x.png" alt="" />
              <p class="fl goback">返回書架</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div
            :class="{
        title: true,
        night: bgCurrent == 5,
        green: bgCurrent == 3,
        blue: bgCurrent == 4,
        pink: bgCurrent == 1,
        yellow: bgCurrent == 2
      }"
    >
      {{ chapterContent.name }}
    </div>
    <div
            :class="{
        detailbg: true,
        night: bgCurrent == 5,
        green: bgCurrent == 3,
        blue: bgCurrent == 4,
        pink: bgCurrent == 1,
        yellow: bgCurrent == 2,
        touch: isDetail
      }"
            @touchstart="down"
            @touchmove.prevent="move"
            @touchend="end"
    >
      <div class="DetailWrap" v-show="!isDetail">
        <div class="bookDetail">
          <div class="bookborder">
            <img
                    v-if="bookInfo.iconUrl"
                    :src="bookInfo.iconUrl"
                    alt=""
            />
            <img class="bg" src="~img/book/bookBorder.png" alt="" />
          </div>

          <p class="bookname">{{ bookInfo.name }}</p>
          <p class="author">{{ authorinfo.name }}</p>
        </div>
        <div class="hint">
          <p>- 版權所有 侵權必究 -</p>
          <div class="bottomHint">
            如您在閱讀中發現涉及：淫穢色情、欺詐<br />廣告、侵權抄襲等問題，請聯系客服舉報
          </div>
        </div>
      </div>
    </div>
    <div
            :class="{
        chapterCon: true,
        night: bgCurrent == 5,
        green: bgCurrent == 3,
        blue: bgCurrent == 4,
        pink: bgCurrent == 1,
        yellow: bgCurrent == 2
      }"
            @click="setAllShow"
            v-if="bookInfo"
    >
      <div class="name">{{ chapterContent.name }}</div>
      <div
              :class="{
          mainCon: true,
          'font-normal': fontCurrent == 0,
          'font-large': fontCurrent == 1,
          'font-xlarge': fontCurrent == 2,
          'font-xxlarge': fontCurrent == 3,
          'font-xxxlarge': fontCurrent == 4
        }"
              v-html="chapterContent.content"
      ></div>
      <div
              class="consumeWrap"
              v-if="!chapterContent.free && chapterContent.payment != null"
      >
        <div class="paybox">
          <p>
            <span>價格：</span
            ><span class="money">{{ chapterContent.payment.price }}書幣</span
          ><span> / 2839字</span>
          </p>
          <p></p>
          <p>
            餘額：<span
          >{{ chapterContent.payment.balance.money }}書幣 /
              {{ chapterContent.payment.balance.coin }}贈幣</span
          ><span class="hint">（優先使用）</span>
          </p>
          <button
                  @click="
              consumeChapter(chapterContent.id, chapterContent.payment.price)
            "
                  v-if="chapterContent.payment.code == 10027"
          >
            購買本章
          </button>
          <button
                  v-if="chapterContent.payment.code !== 10027"
                  class="goconsume"
                  @click.stop="goPayMoney"
          >
            餘額不足，請充值後閱讀
          </button>
          <div class="autoPay clearfix" @click.stop="chooseAuto">
            <p
                    :class="{
                circle: true,
                fl: true,
                night: bgCurrent == 5,
                green: bgCurrent == 3,
                blue: bgCurrent == 4,
                pink: bgCurrent == 1,
                yellow: bgCurrent == 2
              }"
                    v-if="!this.isAuto"
            ></p>
            <img
                    class="select fl"
                    v-if="this.isAuto"
                    src="~img/account/read_option_sel@2x.png"
                    alt=""
            />
            <span>自動購買下一章節</span>
          </div>
        </div>
      </div>

      <div class="authorSay" v-if="chapterContent.additional">
        <p>
          <span>{{ authorinfo.name }}</span> 說：
        </p>
        {{ chapterContent.additional }}
      </div>
    </div>
    <div class="option" v-if="isLoadingover">
      <button class="prev" @click="prebtn">上一章</button>
      <button class="menu" @click="seeMenu">目錄</button>
      <button :class="{ next: true, active: nextIntro }" @click="nextbtn">
        下一章
      </button>
    </div>
    <!-- 目錄 -->
    <div :class="{ menubox: true, isMenu: isMenu }" @click="cancleMe">
      <div class="menucon">
        <div class="NavBox">
          <div class="clearfix top">
            <p class="fl ellipsis">《{{ bookInfo.name }}》</p>
            <a
                    href="javaScript:"
                    class="fr more clearfix"
                    v-show="!isEnd"
                    @click.stop="startList"
            >
              <img class="fl" src="~img/book/read_zx@2x.png" alt="" />
              <span class="fl">
                倒序
              </span>
            </a>
            <a
                    href="javaScript:"
                    v-show="isEnd"
                    class="fr more clearfix"
                    @click.stop="startList"
            >
              <img class="fl" src="~img/book/read_dx@2x.png" alt="" />
              <span class="fl">
                正序
              </span>
            </a>
          </div>
        </div>
        <div class="bscroll" ref="slider">
          <div>
            <!-- 閱讀記錄 -->
            <router-link
                    :to="'/book/' + bookInfo.id + '/' + hisChapters.id"
                    v-if="hisChapters"
                    class="clearfix history"
            >
              <div class="fl clearfix left">
                <img class="fl" src="~img/book/history@2x.png" alt="" />
                <p class="fl">上次閱讀：{{ hisChapters.name }}</p>
              </div>
              <div class="fr clearfix right">
                <p class="fl">繼續閱讀</p>
                <img class="fl" src="~img/book/right.png" alt="" />
              </div>
            </router-link>
            <!-- 目錄 -->
            <ul class="menu">
              <li v-for="item in bookDirectory" :key="item.id">
                <a
                        class="voName clearfix"
                        href="javaScript:"
                        @click.stop="isOpenChapter(item.id)"
                >
                  <div class="fl imgbox">
                    <img
                            v-show="item.isOpen"
                            class="one"
                            src="~img/book/common_unfold@2x.png"
                            alt=""
                    />
                    <img
                            v-show="!item.isOpen"
                            class="two"
                            src="~img/book/common_fold@2x.png"
                            alt=""
                    />
                  </div>

                  <p class="fl">{{ item.name }}</p>
                </a>
                <ul class="chapter" v-show="item.isOpen">
                  <li
                          v-for="chapter in item.chapters"
                          :key="chapter.id"
                          class="item clearfix"
                  >
                    <a
                            href="javaScript:"
                            @click="gochapter(chapter.id)"
                            class="ellipsis"
                    >{{ chapter.name }}
                    </a>
                    <img
                            class="fr"
                            v-if="!chapter.free && chapter.id"
                            src="~img/book/vip@2x.png"
                            alt=""
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 設置總 -->
    <div :class="{ setWrap: true, isshow: isShowSet }">
      <div class="topChapter clearfix">
        <button @click.stop="prebtn" class="fl">上一章</button>
        <button @click.stop="nextbtn" class="fr">下一章</button>
      </div>
      <ul class="setmain xlearfix">
        <li
                :class="{ setItem: true, big: !bookShelf }"
                @click="seeMenu"
        >
          <img src="~img/book/icon_catalog@2x.png" alt="" />
          <p>目錄</p>
        </li>
        <li
                :class="{ setItem: true, big: !bookShelf }"
                @click="openSecond"
        >
          <img src="~img/book/icon_set@2x.png" alt="" />
          <p>設置</p>
        </li>
        <li
                :class="{ setItem: true, fl: true, big: !bookShelf }"
                @click="setNight"
        >
          <img v-show="bgCurrent < 5" src="~img/book/icon_monn@2x.png" alt="" />
          <img v-show="bgCurrent == 5" src="~img/book/read_sun@2x.png" alt="" />
          <p>{{ bgCurrent == 5 ? "日間" : "夜間" }}</p>
        </li>
        <li
                :class="{ setItem: true, big: !bookShelf }"
                v-if="!bookShelf"
                @click.stop="useraddshelf"
        >
          <img src="~img/book/icon_coll@2x.png" alt="" />
          <p>收藏</p>
        </li>
        <li class="setItem" v-if="bookShelf">
          <img src="~img/book/icon_coll@2x.png" alt="" />
          <p>已收藏</p>
        </li>
      </ul>
    </div>
    <!-- 設置字體，背景 -->
    <div :class="{ secondbox: true, isSeconed: isSeconed }">
      <div class="setFont clearfix">
        <span class="fl fontHint">字體大小：</span>
        <div class="reduce fl" @click="deFont">-</div>
        <span class="fl">{{ fontSize }}</span>
        <div class="add fl" @click="incFont">+</div>
      </div>
      <div class="bglist">
        <div class="content clearfix">
          <span class="fl">背景顏色：</span>
          <a
                  href="javaScript:"
                  v-for="(item, index) in choosebgList"
                  :style="{ background: item.bg }"
                  class="fl"
                  @click="changebg(index)"
                  :key="index"
          >
          </a>
        </div>
      </div>
    </div>
    <!--弹出框-->
    <popup-home @closeTG="closeTG" v-if="isPopup" :imgType="imgType" :phoneType="phoneType"></popup-home>

  </div>
</template>

<script>
  import BScroll from "better-scroll";
  import { chapterCon, chapternoCon, Bookmenu, buyChapter } from "apiurl/book";
  import { addreadhistory, readstatus, addshelf, buyAuto } from "apiurl/user";
  import { getToken } from "utils/storage";
  import { setCookie, getCookie } from "utils/core/kernel";
  import {decrypt,asedecrypt} from "@/utils/core/kernel";
  import { lastHistory } from "apiurl/user";
  import Crypto from "@/utils/core/kernel"
  export default {
    data() {
      return {
        chapterContent: { free: true }, //章節內容
        isLoadingover: false,
        nextIntro: {},
        preIntro: {},
        authorinfo: {},
        fontSize: 16,
        bookInfo: {},
        isEnd: false,
        bookDirectory: [],
        isOpen: true,
        hisChapters: null,
        bookId: "",
        chapterId: "",
        isMenu: false, // 展示目錄
        isShowSet: false, // 展示設置
        isSeconed: false,
        fontCurrent: 1,
        bgCurrent: 0,
        choosebgList: [
          { bg: "#FDFDFD" },
          { bg: "#F1D7E0" },
          { bg: "#F7E1B6" },
          { bg: "#5F8E87" },
          { bg: "#CCE4FC" },
          { bg: "#161514" }
        ],
        flags: false,
        isDetail: true,
        position: { x: 0, y: 0 },
        nx: "",
        ny: "",
        moreShow: false,
        bookShelf: false,
        isAuto: false,
        /*收藏弹窗*/
        isPopup: false,
        imgType: '',
        phoneType: '',
      };
    },
    created() {
      this.$nextTick(() => {
        this.fontCurrent = getCookie("fontCurrent") || 1;
        this.bgCurrent = getCookie("bg") || 0;
        this.fontSize = 14 + this.fontCurrent * 2;
        this.tuiguangFun()
        // 獲取書籍目錄

        /*  if (getToken()) {
           this.getAutoBuy();
         } */

      });
    },
    watch: {
      $route(to) {
        this.bookId = to.params.id;
        this.chapterId = to.params.chapterId;
        return this.getCon(this.bookId, this.chapterId);
      }
    },
    methods: {
      seeBack() {
        this.$router.push("/book/" + this.bookInfo.id);
      },
      goMore() {
        this.moreShow = true;
      },
      chooseAuto() {
        this.isAuto = !this.isAuto;
        if (this.isAuto) {
          buyAuto({ bookId: this.bookInfo.id, open: true }).then(res => {
            this.isAutoBuy = true;
            if (res.success) {
              this.Toast({
                message: "自動訂閱已開啓",
                duration: 2000,
                position: "middle"
              });

              if (this.chapterContent.payment) {
                chapternoCon({
                  bookId: this.$route.params.id,
                  chapterId: this.chapterContent.id
                }).then(book => {
                  if (book.success) {
                    let key = decrypt(book.data.chapter.key)
                    this.chapterContent.content = asedecrypt(book.data.chapter.content, key);
                    this.chapterContent.payment =
                            book.data.chapter.payment || null;
                  }
                });
              }
            }
          });
        } else {
          buyAuto({ bookId: this.bookInfo.id, open: false }).then(res => {
            if (res.success) {
              this.Toast({
                message: "自動訂閱已關閉",
                duration: 2000,
                position: "middle"
              });
            }
          });
        }
      },
      goPayMoney() {
        this.$router.push({
          path: "/toup",
          query: {
            redirect: `/book/${this.bookInfo.id}/${this.chapterContent.id}`
          }
        });
      },
      gochapter(id) {
        this.$router.replace({name: 'chapter', params: {id: this.bookId, chapterId: id}})
        // this.getCon(this.bookId, id);
      },
      // 獲取章節內容
      async getCon(bookId, chapterId) {
        let res = await chapterCon({ bookId, chapterId });
        if (res.success) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          this.chapterContent = res.data.chapter;
          let key = decrypt(this.chapterContent.key)
          this.chapterContent.content = asedecrypt(this.chapterContent.content, key);
          this.addHistory(bookId, chapterId);
          this.isLoadingover = true;
          this.nextIntro = res.data.chapter.nextChapter;
          this.preIntro = res.data.chapter.preChapter;
          this.authorinfo = res.data.chapter.author;
          // this.bookInfo = res.data.book;
        }
      },
      seeMenu() {
        this.isMenu = true;
        //   this.isShowSet = false;
      },
      cancleMe() {
        this.isMenu = false;
      },
      //添加歷史記錄
      addHistory(bookId, chapterId) {
        if (getToken()) {
          addreadhistory({
            bookId: bookId,
            chapterId: chapterId
          });
        }
      },
      // 加入書架
      async useraddshelf() {
        let res = await addshelf({ bookId: this.$route.params.id });
        if (res.success) {
          this.bookShelf = true;
          this.Toast({
            message: "已收藏",
            duration: 2000,
            position: "middle"
          });
        }
      },
      prebtn() {
        if (this.preIntro !== undefined) {
          this.$router.replace({name: 'chapter', params: {id: this.bookInfo.id, chapterId: this.preIntro.id}})
          // this.getCon(this.bookInfo.id, this.preIntro.id);
        } else {
          return false;
        }
      },
      nextbtn() {
        if (this.nextIntro !== undefined) {
          this.$router.replace({name: 'chapter', params: {id: this.bookInfo.id, chapterId: this.nextIntro.id}})
          // this.getCon(this.bookInfo.id, this.nextIntro.id);
        } else {
          return false;
        }
      },
      startList() {
        this.isEnd = !this.isEnd;
        this.bookDirectory = this.bookDirectory.reverse();
        this.bookDirectory.forEach((item, index) => {
          item.chapters = item.chapters.reverse();
          item.isOpen = false;
          if (index == 0) {
            item.isOpen = true;
          }
        });
      },
      // 獲取讀者閱讀狀態
      async getReStatus() {
        let res = await readstatus({ bookId: this.bookId });
        if (res.success) {
          this.hisChapters = res.data.hisChapter;
          this.bookShelf = res.data.isShelf;
          console.log(this.bookShelf)
          this.isAuto = res.data.isAuto;
        }
      },
      // 獲取書籍目錄
      async BookMenu() {
        let res = await Bookmenu({ bookId: this.bookId });
        if (res.success) {
          this.bookInfo = res.data.book;
          res.data.menu.forEach((item, index) => {
            if (index == 0) {
              item.isOpen = true;
            } else {
              item.isOpen = false;
            }
          });
          this.bookDirectory = res.data.menu;
          this.firstchapter = this.bookDirectory[0].chapters[0];
          // if (this.firstchapter.id === this.$route.params.chapterId) {
          //   this.isDetail = false;
          // }
        }
      },
      // 是否展開
      isOpenChapter(id) {
        this.bookDirectory.forEach(item => {
          if (item.id === id) {
            item.isOpen = !item.isOpen;
          }
        });
      },
      // 打開設置
      setAllShow() {
        this.moreShow = false;
        if (this.isSeconed) {
          this.isSeconed = false;
          this.isShowSet = false;
        } else {
          this.isShowSet = !this.isShowSet;
        }
      },
      //夜間切換
      setNight() {
        if (this.bgCurrent == 5) {
          this.bgCurrent = 0;
          setCookie("bg", this.bgCurrent, 7);
        } else {
          this.bgCurrent = 5;
          setCookie("bg", this.bgCurrent, 7);
        }
      },
      // 設置字體大小
      incFont() {
        if (this.fontCurrent < 4) {
          this.fontCurrent++;
        } else {
          this.fontCurrent = 4;
        }
        this.fontSize = 14 + this.fontCurrent * 2;
        setCookie("fontCurrent", this.fontCurrent, 7);
      },
      deFont() {
        if (this.fontCurrent > 0) {
          this.fontCurrent--;
        } else {
          this.fontCurrent = 0;
        }
        this.fontSize = 14 + this.fontCurrent * 2;
        setCookie("fontCurrent", this.fontCurrent, 7);
      },
      // 設置bg
      changebg(index) {
        this.bgCurrent = index;
        setCookie("bg", this.bgCurrent, 7);
      },
      // 打開二重設置
      openSecond() {
        this.isSeconed = true;
        this.isShowSet = false;
      },
      // 實現移動端拖拽
      down() {
        this.flags = true;
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.position.x = touch.clientX;
        this.position.y = touch.clientY;
      },
      move() {
        if (this.flags) {
          var touch;
          if (event.touches) {
            touch = event.touches[0];
          } else {
            touch = event;
          }

          this.nx = touch.clientX - this.position.x;
          this.ny = touch.clientY - this.position.y;
          if (this.ny < -100) {
            this.isDetail = true;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        }
      },
      //鼠標釋放時候的函數
      end() {
        this.flags = false;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      consumeChapter(id, price) {
        buyChapter({ chapterId: id, price: price }).then(res => {
          if (res.success) {
            this.chapterContent.content = res.data.chapter.content;
            let key = decrypt(res.data.chapter.key)
            this.chapterContent.content = asedecrypt(this.chapterContent.content, key);
            this.chapterContent.payment = null;
          }
        });
      },
      // 收藏弹窗
      closeTG (data) {
        this.isPopup = false
      },
      // 推广相关
      tuiguangFun () {
        if (this.$route.query.fbsave) {
          if (getToken()) {
            this.gethistory()
          } else {
            var ua = window.navigator.userAgent.toLowerCase()
            var ios = /iphone|ipod|ipad/.test(ua);
            this.isPopup = true
            this.imgType = 'fbsave'
            if (ios) {
              this.phoneType = 'IOS'
            } else {
              this.phoneType = 'android'
            }
            this.bookId = this.$route.params.id;
            this.BookMenu().then(() => {
              if (!this.scroll) {
                this.scroll = new BScroll(this.$refs["slider"], {
                  scrollbar: true,
                  scrollbar: true,
                  click: true
                });
              } else {
                this.scroll.refresh();
              }
            });
            this.getCon(this.$route.params.id, this.$route.params.chapterId)
          }
        } else {
          this.bookId = this.$route.params.id;
          this.BookMenu().then(() => {
            if (!this.scroll) {
              this.scroll = new BScroll(this.$refs["slider"], {
                scrollbar: true,
                scrollbar: true,
                click: true
              });
            } else {
              this.scroll.refresh();
            }
          });
          this.getCon(this.$route.params.id, this.$route.params.chapterId);
          // 歷史記錄
          if (getToken()) {
            this.getReStatus();
          }
        }
      },
      async gethistory() {
        let res = await lastHistory();
        if (res.success) {
          if (res.data) {
            // let location = '/book/' + res.data.bookId + '/' + res.data.chapterId
            // let location = '/book/1460111500483235841/1478395770414829570'
            // this.$router.push(location)
            this.$router.push(`/book/${res.data.bookId}/${res.data.chapterId}`)
          } else {
            this.getCon(this.$route.params.id, this.$route.params.chapterId);
          }
          this.bookId = this.$route.params.id;
          this.BookMenu().then(() => {
            if (!this.scroll) {
              this.scroll = new BScroll(this.$refs["slider"], {
                scrollbar: true,
                scrollbar: true,
                click: true
              });
            } else {
              this.scroll.refresh();
            }
          });
        }
      },
    },
    mounted() {}
  };
</script>
<style>
  .bscroll-vertical-scrollbar {
    display: none;
  }
</style>

<style lang="stylus" scoped>
  .fixed
    position fixed
    width 100%
    top -110px
    transition all 0.17s ease
    z-index 10
    &.isShow
      top 0px
    .topbox
      background rgba(0, 0, 0, 0.71)
      p
        color #fff
      .more
        img
          width 36px
          margin-top 30px
    .options
      width 328px
      height 290px
      overflow hidden
      position absolute
      right 24px
      bottom -300px
      border-radius 7px
      .bg
        position absolute
        z-index -1
        width 100%
        height 290px
        top 0
    .moreOption
      width:310px;
      padding 34px 26px 15px 0px
      li
        border-bottom 1px solid #666
        padding-bottom 30px
        margin-left 9px
        &:nth-child(2)
          padding 21px 25px
        .detailimg
          width:97px;
          height:136px;
          border-radius 5px
          margin-right 21px
          margin-left 22px
          img
            width 97px
            height 136px
        img
          width 28px
          height 28px
        p
          color #fff
          width 160px
          &.name
            font-size:32px;
          &.author
            font-size 24px
            margin 7px 0 13px 0
          &.more
            font-size:22px;
          &.goback
            line-height 28px
            margin-left 20px
  .title
    position fixed
    background #fdfdfd
    top 0px
    width 100%
    color #444
    font-size 20px
    padding 30px 26px 30px
    &.green
      color #A9C8C2
      background #56817B
    &.blue
      color #3B5375
      background #CBE5FC
    &.yellow
      color #644A27
      background #F6E0B5
    &.pink
      color #6A1A35
      background #F1D7E0
    &.night
      background #161514
      color #957763
  .chapterWrap
    color #444
    position relative
    background #fdfdfd
    min-height 100vh
    &.green
      color #A9C8C2
      background #56817B
    &.blue
      color #3B5375
      background #CBE5FC
    &.yellow
      color #644A27
      background #F6E0B5
    &.pink
      color #6A1A35
      background #F1D7E0
    &.night
      background #161514
      color #957763
    .detailbg
      position fixed
      width 100vw
      height 100vh
      top 0
      left 0
      background rgba(253, 253, 253, 1)
      &.touch
        transition all 0.5s ease
        top -100vh
      &.green
        color #A9C8C2
        background #56817B
      &.blue
        color #3B5375
        background #CBE5FC
      &.yellow
        color #644A27
        background #F6E0B5
      &.pink
        color #6A1A35
        background #F1D7E0
      &.night
        background #161514
        color #957763
    .DetailWrap
      top 48px
      left 24px
      right 26px
      bottom 22px
      border-radius:60px;
      border:5px solid rgba(151,151,151,0.1984);
      text-align center
      position fixed
      .bookborder
        position relative
        width 324px
        height 478px
        margin 105px auto 38px
        img
          display block
          width 324px
          height 478px
        .bg
          position absolute
          top 0px
          left 0px
          width 324px
          height 478px
          z-index 10
      .bookname
        font-size:46px;
        font-weight:600;
      .author
        font-size:26px;
        font-weight 600
        color:rgba(34,137,241,1);
        margin-bottom 65px
      .hint
        width:749px;
        height:414px;
        background:rgba(255,255,255,0.05);
        position absolute
        bottom -22px
        z-index 2
        left -26px
        padding-top 80px
        div
          margin-top 68px
        .bottomHint
          margin-bottom 14px
          line-height:35px;
          position absolute
          bottom 80px
          left 0
          right 0
          margin 0 auto
    .bg
      height calc(100vh)
      width 100vw
      position fixed
      z-index:-1
      img
        width 100%
        height 100%
    .chapterCon
      padding 120px 28px 40px 32px
      font-size 30px
      line-height 60px
      &.green
        color #A9C8C2
      &.blue
        color #3B5375
      &.yellow
        color #644A27
      &.pink
        color #6A1A35
      &.night
        background #161514
        color #957763
      .name
        font-weight 600
        font-size 36px
        padding-bottom 30px
      .mainCon
        text-indent 30px
        letter-spacing 1px
        line-height 80px
        white-space pre-line
        &.font-normal
          font-size 30px
        &.font-large
          font-size 35px
        &.font-xlarge
          font-size 40px
        &.font-xxlarge
          font-size 45px
        &.font-xxxlarge
          font-size 50px
      .authorSay
        margin-top 36px
        color #9B9B9B
        font-size 34px
        line-height 60px
        span
          color #258BF2
          display inline-block
          margin-right 15px
      .consumeWrap
        border-top 1px dashed #979797
        padding-top 72px
        margin 86px 0 63px
      .paybox
        font-size 28px
        width 490px
        margin 0 auto
        button
          width:452px;
          height:68px;
          border-radius:34px;
          background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
          box-shadow:0px 1px 8px 0px rgba(118,206,254,1);
          text-align center
          font-size:28px;
          color #fff
          margin-top 26px
        .hint
          font-size 24px
        .money
          color #008EFB
        .autoPay
          line-height 32px
          margin-top 30px
          font-size 28px
          .circle
            width 28px
            height 28px
            border 2px solid #444
            border-radius 50%
            display block
            margin-right 15px
            &.green
              border-color #A9C8C2
            &.blue
              border-color #3B5375
            &.yellow
              border-color #644A27
            &.pink
              border-color #6A1A35
            &.night
              border-color #957763
          img
            width 32px
            height 32px
            margin-right 15px
    .option
      padding 0 28px 60px 30px
      button
        width 210px
        height 64px
        background rgba(244, 248, 252, 1)
        border-radius 8px
        border 2px solid rgba(238, 238, 238, 1)
        color #333333
        font-size 24px
        &.menu
          margin 0 30px
        &.active
          background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
          border none
          color #fff
  .bscroll
    // max-height calc(100vh - 310px)
    overflow hidden
    position absolute
    top 100px
    bottom 0px
    left 0;
    right 131px
  .menubox
    position fixed
    right 200px
    width 100%
    z-index 10
    top 0
    left -100%
    bottom 0
    background rgba(0, 0, 0, 0.48)
    // height 100vh
    transition all 0.17s ease
    &.isMenu
      left 0px
    .menucon
      background #fff
      margin-right 131px
      height 100vh
    .top
      height 100px
      line-height 100px
      padding 0 27px
      position relative
      background rgba(255, 255, 255, 1)
      box-shadow 0 0 0 0 rgba(0, 0, 0, 0.05)
      .more
        color #555555
        font-size 30px
        img
          width 36px
          height 36px
          margin-top 32px
          margin-right 7px
        span
          display inline-block
          height 100%
          color #999999
      p
        font-size 34px
        color #333333
        width 70%
        overflow hidden
    .history
      height 76px
      line-height 76px
      background rgba(250, 250, 250, 1)
      font-size 24px
      color rgba(153, 153, 153, 1)
      display block
      .left
        margin-left 24px
        img
          width 28px
          height 28px
          margin-top 24px
          margin-right 9px
      .right
        margin-right 24px
        color #298FF3
        img
          width 13px
          height 22px
          margin-top 27px
          margin-left 14px
    .menu
      li
        .voName
          font-size 30px
          color #333
          height 82px
          line-height 82px
          padding-left 24px
          display block
          font-size 30px
          border-bottom 2px solid rgba(238, 238, 238, 1)
          .imgbox
            width 20px
            height 20px
            margin-right 10px
            img
              width 20px
              height 10px
              &.two
                width 10px
                height 20px
        .chapter
          display block
          .item
            padding-left 58px
            height 82px
            line-height 82px
            border-bottom 2px solid rgba(238, 238, 238, 1)
            a
              color:rgba(153,153,153,1);
              font-size 28px
              width 80%
              display inline-block
              overflow hidden
            img
              width 28px
              height 28px
              margin 29px 32px 0 0
  .setWrap
    width 100%
    height 207px
    background rgba(0, 0, 0, 1)
    position fixed
    bottom -207px
    left 0
    transition all 0.17s ease
    z-index 2
    &.isshow
      bottom 0
    .topChapter
      height 72px
      line-height 72px
      border-bottom 1px solid #999999
      button
        background transparent
        font-size 28px
        color #fff
        height 72px
        margin-left 69px
        margin-right 65px
    .setmain
      /*margin 0 79px 0 83px*/
      display flex
      -webkit-display flex
      .setItem
        flex 1;
        -webkit-flex 1;
        /*margin-right 112px*/
        &.big
          /*margin-right 119px*/
        &:nth-child(4)
          margin-right 0px
        img
          width 48px
          height 48px
          margin-top 23px
          display block
          margin 23px auto 0
        p
          font-size 28px
          color #FFFFFF
          margin-top 6px
          text-align center
  .secondbox
    width 100%
    height 235px
    background rgba(0, 0, 0, 0.71)
    position fixed
    bottom -235px
    left 0
    transition all 0.17s ease
    z-index 2
    &.isSeconed
      bottom 0px
    .setFont
      margin 33px 0px 51px 70px
      div
        width 166px
        height 56px
        background rgba(255, 255, 255, 0)
        border-radius 30px
        border 2px solid rgba(255, 255, 255, 1)
        text-align center
        line-height 56px
        font-size 36px
        color #fff
      span
        font-size 36px
        line-height 56px
        margin 0 40px
        color #fff
      .fontHint
        font-size 28px
        margin 0
    .bglist
      margin 0 30px 0 70px
      span
        font-size 28px
        color #fff
      a
        width 40px
        display block
        height 40px
        border-radius 50%
        margin-right 38px
</style>
